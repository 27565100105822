var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2",attrs:{"id":"products"}},[_c('Nav'),_c('el-row',{staticClass:"products-content",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"xl":20,"lg":23}},[_c('el-row',{staticClass:"mt-3 mb-3",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":12}},[_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":24}},[_c('h1',[_vm._v("Produits"),_c('el-button',{staticClass:"is-add-action",attrs:{"circle":"","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$router.push({ name: 'product_create' })}}})],1)])],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-row',{attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":10}},[_c('el-form',{nativeOn:{"submit":function($event){$event.preventDefault();return _vm.getProducts()}}},[_c('el-form-item',{staticClass:"mb-0"},[_c('el-input',{staticClass:"is-search",attrs:{"placeholder":"Rechercher par nom","suffix-icon":"el-icon-search","clearable":""},on:{"clear":function($event){return _vm.getProducts()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)],1)],1),_c('el-table',{attrs:{"id":"productsTable","data":_vm.products,"cell-class-name":"product-cell"},on:{"cell-click":_vm.goToEditProduct}},[_c('el-table-column',{attrs:{"class-name":"clickable is-bold","prop":"name","label":"Nom","sortable":"","min-width":"200px"}}),_c('el-table-column',{attrs:{"prop":"structure","label":"Structure","class-name":"clickable structure","sortable":"","filters":_vm.structureFilter,"filter-method":_vm.handleStructureFilter,"min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getStructureName(scope.row.structure))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"Type","sortable":"","filters":[
            { text: 'Frais', value: 'frais' },
            { text: 'Emoluments', value: 'emolument' },
            { text: '% Acquéreur', value: 'percent_buyer' },
            { text: 'Fixe Acquéreur', value: 'fixe_buyer' },
            { text: '% Vendeur', value: 'percent_seller' },
            { text: 'Fixe Vendeur', value: 'fixe_seller' } ],"filter-method":_vm.handleFilter,"class-name":"clickable","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getProductType(scope.row.type))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"unitPriceExcludingTaxes","label":"Prix unitaire HT","sortable":"","class-name":"clickable","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"tvaRate","label":"Taux TVA","sortable":"","class-name":"clickable","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getProductTaxRate(scope.row.tvaRate, scope.row.structure))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"accountingAccount","label":"Compte Comptable","sortable":"","class-name":"clickable","min-width":"120px"}}),_c('el-table-column',{attrs:{"prop":"active","label":"Actif","sortable":"","filters":[
            { text: 'Oui', value: 1 },
            { text: 'Non', value: 0 } ],"filter-method":_vm.handleFilter,"class-name":"clickable","min-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getProductActive(scope.row.active))+" ")]}}])}),_c('el-table-column',{attrs:{"class-name":"actions","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link action-dropdown"},[_c('span'),_c('span'),_c('span')]),_c('el-dropdown-menu',{staticClass:"actions",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){$event.preventDefault();return _vm.editProduct(scope.row)}}},[_vm._v("Éditer")])],1),_c('el-dropdown-item',[_c('el-link',{attrs:{"underline":false,"type":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteProduct(scope.row)}}},[_vm._v("Supprimer le produit")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }